// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "1": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "1" */),
  "2": () => import("./../../../src/pages/404.js" /* webpackChunkName: "2" */),
  "3": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "3" */),
  "4": () => import("./../../../src/pages/index.js" /* webpackChunkName: "4" */),
  "5": () => import("./../../../src/pages/pocet-cajoven-v-cr-a-krajich.js" /* webpackChunkName: "5" */),
  "6": () => import("./../../../src/pages/studijni-materialy.js" /* webpackChunkName: "6" */),
  "7": () => import("./../../../src/pages/tropicke-cyklony-vlivem-klimaticke-zmeny-meni-mista-vyskytu.js" /* webpackChunkName: "7" */),
  "8": () => import("./../../../src/pages/zajem-koronavirus-google-casosberna-mapa-grafy-data.js" /* webpackChunkName: "8" */),
  "9": () => import("./../../../src/pages/ekologie-zivotni-prostredi/chemicky-boj-alelopatie.js" /* webpackChunkName: "9" */),
  "10": () => import("./../../../src/pages/ekologie-zivotni-prostredi/dalsi-ekologicke-faktory.js" /* webpackChunkName: "10" */),
  "11": () => import("./../../../src/pages/ekologie-zivotni-prostredi/historie-lesu.js" /* webpackChunkName: "11" */),
  "12": () => import("./../../../src/pages/ekologie-zivotni-prostredi/index.js" /* webpackChunkName: "12" */),
  "13": () => import("./../../../src/pages/ekologie-zivotni-prostredi/klasifikace-lesu.js" /* webpackChunkName: "13" */),
  "14": () => import("./../../../src/pages/ekologie-zivotni-prostredi/lesnicka-legislativa.js" /* webpackChunkName: "14" */),
  "15": () => import("./../../../src/pages/ekologie-zivotni-prostredi/nedostatek-nadbytek-zivin.js" /* webpackChunkName: "15" */),
  "16": () => import("./../../../src/pages/ekologie-zivotni-prostredi/okyseleni-prostredi.js" /* webpackChunkName: "16" */),
  "17": () => import("./../../../src/pages/ekologie-zivotni-prostredi/ph-reakce-prostredi.js" /* webpackChunkName: "17" */),
  "18": () => import("./../../../src/pages/ekologie-zivotni-prostredi/pojmy-lesnictvi.js" /* webpackChunkName: "18" */),
  "19": () => import("./../../../src/pages/ekologie-zivotni-prostredi/prichod-a-vymirani-druhu.js" /* webpackChunkName: "19" */),
  "20": () => import("./../../../src/pages/ekologie-zivotni-prostredi/repatriace-navrat-introdukce.js" /* webpackChunkName: "20" */),
  "21": () => import("./../../../src/pages/ekologie-zivotni-prostredi/uvod.js" /* webpackChunkName: "21" */),
  "22": () => import("./../../../src/pages/ekologie-zivotni-prostredi/zivotni-strategie.js" /* webpackChunkName: "22" */),
  "23": () => import("./../../../src/pages/ekologie-zivotni-prostredi/zivotni-vzrustove-formy.js" /* webpackChunkName: "23" */),
  "24": () => import("./../../../src/pages/gis/index.js" /* webpackChunkName: "24" */),
  "25": () => import("./../../../src/pages/hydrologie/cleneni-svetoveho-oceanu.js" /* webpackChunkName: "25" */),
  "26": () => import("./../../../src/pages/hydrologie/hydrologicky-cyklus.js" /* webpackChunkName: "26" */),
  "27": () => import("./../../../src/pages/hydrologie/index.js" /* webpackChunkName: "27" */),
  "28": () => import("./../../../src/pages/hydrologie/mokrady.js" /* webpackChunkName: "28" */),
  "29": () => import("./../../../src/pages/hydrologie/podpovrchova-voda.js" /* webpackChunkName: "29" */),
  "30": () => import("./../../../src/pages/hydrologie/prameny-mineralni-vody.js" /* webpackChunkName: "30" */),
  "31": () => import("./../../../src/pages/hydrologie/protipovodnova-opatreni.js" /* webpackChunkName: "31" */),
  "32": () => import("./../../../src/pages/hydrologie/svetovy-ocean.js" /* webpackChunkName: "32" */),
  "33": () => import("./../../../src/pages/hydrologie/uvod-do-studia.js" /* webpackChunkName: "33" */),
  "34": () => import("./../../../src/pages/hydrologie/vodni-nadrze.js" /* webpackChunkName: "34" */),
  "35": () => import("./../../../src/pages/zaklady-geodezie-gps/dejiny-geodezie.js" /* webpackChunkName: "35" */),
  "36": () => import("./../../../src/pages/zaklady-geodezie-gps/gnss-systemy.js" /* webpackChunkName: "36" */),
  "37": () => import("./../../../src/pages/zaklady-geodezie-gps/index.js" /* webpackChunkName: "37" */),
  "38": () => import("./../../../src/pages/zaklady-geodezie-gps/katastr-nemovitosti.js" /* webpackChunkName: "38" */),
  "39": () => import("./../../../src/pages/zaklady-geodezie-gps/mereni-uhlu-smeru.js" /* webpackChunkName: "39" */),
  "40": () => import("./../../../src/pages/zaklady-geodezie-gps/priprava-pristroje-chyby-mereni-uhlu.js" /* webpackChunkName: "40" */),
  "41": () => import("./../../../src/pages/zaklady-geodezie-gps/pristroje-a-pomucky-pro-mereni-smeru-a-uhlu.js" /* webpackChunkName: "41" */),
  "42": () => import("./../../../src/pages/zaklady-geodezie-gps/rozdeleni-geodezie.js" /* webpackChunkName: "42" */),
  "43": () => import("./../../../src/pages/zaklady-geodezie-gps/vypocet-smerniku-rajonu.js" /* webpackChunkName: "43" */),
  "44": () => import("./../../../src/pages/zaklady-geodezie-gps/vysilane-signaly-gnss.js" /* webpackChunkName: "44" */),
  "45": () => import("./../../../src/pages/gis/mapova-kompozice/index.js" /* webpackChunkName: "45" */),
  "46": () => import("./../../../src/pages/gis/mapova-kompozice/legenda.js" /* webpackChunkName: "46" */),
  "47": () => import("./../../../src/pages/gis/mapova-kompozice/meritko.js" /* webpackChunkName: "47" */),
  "48": () => import("./../../../src/pages/gis/mapova-kompozice/nazev-mapy.js" /* webpackChunkName: "48" */),
  "49": () => import("./../../../src/pages/gis/mapova-kompozice/smerovka.js" /* webpackChunkName: "49" */),
  "50": () => import("./../../../src/pages/biogeografie/definice.js" /* webpackChunkName: "50" */),
  "51": () => import("./../../../src/pages/biogeografie/historie.js" /* webpackChunkName: "51" */),
  "52": () => import("./../../../src/pages/biogeografie/index.js" /* webpackChunkName: "52" */),
  "53": () => import("./../../../src/pages/biogeografie/areografie/faktory-ovlivnujici-rozsireni-druhu.js" /* webpackChunkName: "53" */),
  "54": () => import("./../../../src/pages/biogeografie/areografie/index.js" /* webpackChunkName: "54" */),
  "55": () => import("./../../../src/pages/biogeografie/areografie/makroekologicke-vlastnosti-arealu.js" /* webpackChunkName: "55" */),
  "56": () => import("./../../../src/pages/biogeografie/areografie/typy-rozsireni.js" /* webpackChunkName: "56" */),
  "57": () => import("./../../../src/pages/biogeografie/biodiverzita/globalni-druhove-bohatstvi.js" /* webpackChunkName: "57" */),
  "58": () => import("./../../../src/pages/biogeografie/biodiverzita/index.js" /* webpackChunkName: "58" */),
  "59": () => import("./../../../src/pages/biogeografie/biodiverzita/lokalni-druhove-bohatstvi.js" /* webpackChunkName: "59" */),
  "60": () => import("./../../../src/pages/biogeografie/biodiverzita/sar-iar-vztah-diverzity-plochy.js" /* webpackChunkName: "60" */),
  "61": () => import("./../../../src/pages/biogeografie/disperze/biogeograficke-sireni.js" /* webpackChunkName: "61" */),
  "62": () => import("./../../../src/pages/biogeografie/disperze/ekologicke-sireni.js" /* webpackChunkName: "62" */),
  "63": () => import("./../../../src/pages/biogeografie/disperze/index.js" /* webpackChunkName: "63" */),
  "64": () => import("./../../../src/pages/biogeografie/ekologicka/abioticke-faktory.js" /* webpackChunkName: "64" */),
  "65": () => import("./../../../src/pages/biogeografie/ekologicka/biogeograficka-pravidla.js" /* webpackChunkName: "65" */),
  "66": () => import("./../../../src/pages/biogeografie/ekologicka/bioticke-interakce.js" /* webpackChunkName: "66" */),
  "67": () => import("./../../../src/pages/biogeografie/ekologicka/ekologicke-faktory.js" /* webpackChunkName: "67" */),
  "68": () => import("./../../../src/pages/biogeografie/ekologicka/index.js" /* webpackChunkName: "68" */),
  "69": () => import("./../../../src/pages/biogeografie/evolucni/index.js" /* webpackChunkName: "69" */),
  "70": () => import("./../../../src/pages/biogeografie/evolucni/koncepty-druhu.js" /* webpackChunkName: "70" */),
  "71": () => import("./../../../src/pages/biogeografie/evolucni/makroevoluce.js" /* webpackChunkName: "71" */),
  "72": () => import("./../../../src/pages/biogeografie/evolucni/speciace.js" /* webpackChunkName: "72" */),
  "73": () => import("./../../../src/pages/biogeografie/invazni-ekologie/faktory-invazivnosti-a-invazibility.js" /* webpackChunkName: "73" */),
  "74": () => import("./../../../src/pages/biogeografie/invazni-ekologie/index.js" /* webpackChunkName: "74" */),
  "75": () => import("./../../../src/pages/biogeografie/invazni-ekologie/invaze-zavlecene-druhy-a-dalsi-terminologie.js" /* webpackChunkName: "75" */),
  "76": () => import("./../../../src/pages/biogeografie/invazni-ekologie/invazni-procesy-ve-svete-a-cr.js" /* webpackChunkName: "76" */),
  "77": () => import("./../../../src/pages/biogeografie/ostrovni/index.js" /* webpackChunkName: "77" */),
  "78": () => import("./../../../src/pages/biogeografie/ostrovni/zvlastnosti-bioty.js" /* webpackChunkName: "78" */),
  "79": () => import("./../../../src/pages/biogeografie/spolecenstva/globalni-biomy.js" /* webpackChunkName: "79" */),
  "80": () => import("./../../../src/pages/biogeografie/spolecenstva/index.js" /* webpackChunkName: "80" */),
  "81": () => import("./../../../src/pages/biogeografie/spolecenstva/vegetace-evropy.js" /* webpackChunkName: "81" */)
}

